.project {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    flex: 0 0 auto;
    transition: transform 0.3s ease;
    width: 300px;
    cursor: pointer;
  }
  
  .project:hover {
    transform: scale(1.05);
  }
  
  .project h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: black;
  }
  
  .project p {
    color: black;
    margin: 5px 0;
  }
  
  .github-link {
    color: #0366d6;
    text-decoration: none;
    font-weight: bold;
  }
  
  .github-link:hover {
    text-decoration: underline;
  }
  