.container-header {
    display: flex; 
    align-items: center;
    margin: 3rem;
}

img {
    border-radius: 45%;
    height: 70vh;
}

.image {
    flex: 0 0 auto;
    margin-right: 1rem; 
    height: 60vh;
}

.profile {
    flex: 1;
    margin: 2rem;
}

h1 {
    font-size: 90px;
    margin: 2rem 2px;
}

h4 {
    font-weight: 100;
}

.contact {
    display: flex;
    justify-content: center;
    margin: 2rem;
    gap: 2rem;
}

button {
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    border-radius: 10px;
    padding: 12px 20px;
    background-color: #7a5cc0;
    border: none;
    cursor: pointer;
    gap: 5px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
    background-color: #e0e0e0; /* Lätt mörkare bakgrund vid hover */
}

button img {
    height: 24px; /* Justera höjden på ikonen */
    margin-right: 10px; /* Avstånd mellan ikonen och texten */
}

button:active {
    background-color: #d0d0d0; /* Ändra bakgrund när knappen trycks ned */
}

.github {
    height: 45px;
}

@media screen and (max-width: 1024px) {
    .image {
        flex: 0 0 auto;
        margin-right: 1rem; 
        height: 60vh;
    }

    img {
        border-radius: 45%;
        height: 30vh;
    }

}

@media screen and (max-width: 600px) {
    .container {
        display: block;
        width: 90%;
    }

    .profile {
        margin: 10px;
    }

    .image {
        margin: 30px 10px;
        height: 50vh;
    }

    img {
        border-radius: 45%;
        height: 50vh;
    }

    h1 {
        font-size: 50px;
    }
}


