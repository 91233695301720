@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {

    --color-white: white;
    --color-bg: #180524;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

}

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

}