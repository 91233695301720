.container-nav {
    display: flex;
    background-color: #000001;
    padding: 20px 40px;
    gap: 4rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header-item {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.nav-item {
    margin-left: 30px;
    font-size: 18px;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

.header-item {
    margin-right: auto; 
}

.nav-item:first-child {
    margin-left: auto;
}

.nav-item:hover {
    color: #7a5cc0;
}

.nav-item:active {
    color: #7a5cc0;
}

@media screen and (max-width: 768px) {
    .container-nav {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-item {
        margin-left: 0;
        margin-top: 10px;
    }
}
